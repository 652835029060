import React from 'react'

import { Footer } from '../common/footer'
import { Navigation } from '../common/navigation'
import { GlobalHelmet } from '../common/seo/globalHelmet'
import { CMSWrapper } from '../components/cmsWrapper'
import { StaticReferenceModules } from '../components/references/staticReferenceModules'
import { StationsShortlist } from '../components/stations/stationsShortlist'
import { graphql } from 'gatsby'
import { AREA_MFA_PATH, AREA_MFA } from '../config/constants'
import { Button } from '../common/button'

export default function Index({ pageContext, data }) {
  const areaPageReferences = data.allReference.nodes
  return (
    <CMSWrapper>
      <GlobalHelmet title="UKE jukebox - MFA" />
      <main>
        <Navigation />
        <header className="bg-img-yellow divider-left header--static">
          <div className="row align-items-center">
            <div className="col-md-5 offset-md-1 col-10 offset-1 text-center">
              <img
                src="/img/handlettering/handlettering-mit-uns-laueufts-rund.svg"
                alt="Handlettering: Mit uns läuft's rund"
                title="Mit uns läuft's rund"
                width="370"
              />
            </div>

            <div className="col-md-5 offset-md-0 col-10 offset-1 text-center">
              <img
                src="/img/mfa/illustration-mfa.png"
                alt="Illustration: MFA"
              />
            </div>

            <div className="col-md-10 col-10 offset-1 text-center mt-4">
              <h1>Medizinische:r Fachangestellte:r (MFA)</h1>
              <h2>
                Ohne dich geht nix! Als MFA bist du das Herzstück des Bereichs.
              </h2>
            </div>
          </div>
        </header>

        <section id="matching-stats">
          <div id="stats" className="row align-items-center text-center">
            <div className="col-md-4 col-12">
              <h3>Unser Bereich in Zahlen:</h3>
            </div>
            <div className="col-md-4 col-6">
              <figure>
                <img
                  src="/img/icons/icon-team.png"
                  height="80"
                  width="80"
                  alt="Team"
                  loading="lazy"
                />
                <figcaption>
                  <h4>
                    MFAs im UKE <br />
                    <span>&gt;300</span>
                  </h4>
                </figcaption>
              </figure>
            </div>
            <div className="col-md-4 col-6">
              <figure>
                <img
                  src="/img/icons/icon-signpost.png"
                  height="80"
                  width="80"
                  alt="Möglichkeiten"
                  loading="lazy"
                />
                <figcaption>
                  <h4>
                    Möglichkeiten <br />
                    <span>&infin;</span>
                  </h4>
                </figcaption>
              </figure>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-right">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Ohne dich läuft nichts!</h2>
              <p>
                Du bist der Dreh- und Angelpunkt der Abteilung, der erste
                Kontakt für die Patient:innen, das Bindeglied zu Pflege und
                Ärzt:innen und anderen Kolleg:innen im interdisziplinären Team
                und der ruhende Pol, wenn die Wellen mal höher schlagen.
              </p>
              <p>
                Als ausgebildete MFA bist du fachlich fit, liebst die
                Herausforderung – und willst den Wechsel ins UKE wagen? Dann
                darfst du dich schon jetzt auf eine verantwortungsvolle und
                abwechslungsreiche Tätigkeit freuen: Das UKE bietet ein
                einzigartiges Spektrum an Einsatzmöglichkeiten. Mit oder ohne
                Bereichsrotation, fachspezifisch oder berufsübergreifend. Im
                kleinen oder großen Team, Voll- oder Teilzeit.
              </p>
              <div className="grid-container grid-container--static align-items-center">
                <div className="image-container image-container--frame image-container--static">
                  <img
                    src="/img/mfa/BSS_MFA_Bild_1.jpg"
                    alt="BSS MFA Bild 1"
                    title="BSS MFA Bild 1"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-left">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Du hast die Wahl – stöbere dich durch unsere Bereiche!</h2>
              <div className="grid-container grid-container--static align-items-center">
                <div className="image-container image-container--frame image-container--static my-4">
                  <img
                    src="/img/mfa/BSS_MFA_Bild_2.jpg"
                    alt="BSS MFA Bild 2"
                    title="BSS MFA Bild 2"
                    loading="lazy"
                  />
                </div>
              </div>
              <p>
                In vielen Bereichen wie dem Universitären Herz- und Gefäßzentrum
                des UKE, den Zentralambulanzen und dem Ambulanzzentrum (MVZ)
                sorgst du im MFA-Team dafür, dass es im Behandlungsalltag wie am
                Schnürchen läuft.
              </p>
              <p>
                Du unterstützt beispielsweise den reibungslosen Ablauf der
                Sprechstunde, die Terminplanung und -koordination, die Erfassung
                und Pflege der Patient:innendaten sowie die
                Behandlungsdokumentation.
              </p>
              <p>
                Je nach Einsatzbereich assistierst du bei Untersuchungen und
                Behandlungen, erstellst EKGs, nimmst Blut ab, legst
                Venenverweilkanülen, koordinierst die Termine und betreust und
                berätst die Patient:innen. Mit deiner Empathie und Gelassenheit
                trägst du zu einer entspannten Atmosphäre bei.
              </p>
              <p>
                Unsicher, welcher Fachbereich der Richtige für dich ist? Kein
                Problem, das kannst du unkompliziert über eine Hospitation
                herausfinden. Melde dich direkt in deinem Wunschbereich oder
                scrolle zu unseren Ansprechpersonen am Ende der Seite.
              </p>
              <div className="text-center">
                <Button
                  color="yellow"
                  className="my-2"
                  href={`${AREA_MFA_PATH}/stationen`}
                >
                  Stöbern
                </Button>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-right">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Sicher ist: Wir suchen Verstärkung – und wollen dich!</h2>
              <p>
                Ob Berufsanfänger:in, Wiedereinsteiger:in oder erfahrene:r MFA:
                Wir haben die{' '}
                <a href="https://www.uke.jobs/index.php?ac=search_result&search_criterion_activity_level%5B%5D=2">
                  passende Stelle
                </a>{' '}
                für dich im UKE.
              </p>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-left">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Werde Teil unseres MFA-Teams!</h2>
              <p>
                <strong>Das hast du davon:</strong>
              </p>
              <ul>
                <li>
                  Attraktiver Arbeitgeber: Bei uns findest du einen sicheren
                  Arbeitsplatz, kannst dich entwickeln und über dich
                  hinauswachsen
                </li>
                <li>
                  Exzellenz: Das UKE zählt zu den modernsten Kliniken Europas,
                  in der sich Spitzenmedizin, Forschung und Lehre vernetzen; bei
                  neuen Untersuchungs- und Therapieverfahren bist du stets am
                  Puls der Zeit
                </li>
                <li>
                  Spannender Arbeitsalltag, indem du Wertschätzung und
                  Unterstützung erfährst und dich voll einbringen kannst
                </li>
                <li>
                  Faire Bezahlung analog zum Tarifvertrag des Öffentlichen
                  Dienstes; weitere Vergütungsbestandteile; 30 Tage Urlaub im
                  Jahr
                </li>
                <li>Betriebliche Altersversorgung</li>
                <li>
                  Familienfreundlichkeit: Das UKE ist preisgekrönt für die
                  Vereinbarkeit von Familie, Beruf und Freizeit (kostenlose
                  Ferienbetreuung, UKE-eigene KiTa mit schicht-kompatiblen
                  Betreuungszeiten)
                </li>
                <li>
                  Zahlreiche Gesundheits- und Präventionsangebote wie
                  Akupunktur, Outdoor-Training, Segeln, Yoga, etc.
                </li>
                <li>
                  Zuschuss zum HVV-ProfiTicket, Dr. Bike Fahrradservice und
                  Fahrradleasing-Angebote
                </li>
                <li>Unterstützung bei der Wohnungssuche</li>
                <li>
                  Umzugspauschale: bis zu 2.000 Euro für die anfallenden Kosten,
                  wenn ein Umzug (von außerhalb des HVV-Gesamtbereichs) nach
                  Hamburg erforderlich ist. Sprich dieses Thema am besten gleich
                  beim Bewerbungsgespräch an
                </li>
                <li>Individuelle & intensive Einarbeitung</li>
              </ul>
              <div className="grid-container grid-container--static align-items-center">
                <div className="image-container image-container--frame image-container--static">
                  <img
                    src="/img/mfa/BSS_MFA_Bild_3.jpg"
                    alt="BSS MFA Bild 3"
                    title="BSS MFA Bild 3"
                    loading="lazy"
                  />
                </div>
                <div className="image-container image-container--frame image-container--static">
                  <img
                    src="/img/mfa/BSS_MFA_Bild_4.jpg"
                    alt="BSS MFA Bild 4"
                    title="BSS MFA Bild 4"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-right">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Mach dir keinen Kopf!</h2>
              <p>
                Du denkst, das UKE sei eine Nummer zu groß für dich? Keine
                Sorge, alles ist machbar, und außerdem bist du nicht allein.
                Dein Team steht dir bei Fragen immer zur Seite.
              </p>
              <p>
                <strong>
                  Ob Patient:in oder Mitarbeiter:in – bei uns steht der Mensch
                  im Mittelpunkt.
                </strong>
              </p>
              <p>
                Wir passen aufeinander auf und sorgen dafür, dass du einen guten
                Start hast.
              </p>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-right">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2 className="mb-4">Deine Einarbeitung: maßgeschneidert</h2>
              <h3>UKE-Einführungstage</h3>
              <p>
                Gemeinsam mit anderen neuen Mitarbeiter:innen lernst du als
                erstes berufsübergreifend 2 bis 5 Tage (je nach Einsatzbereich)
                das UKE kennen. Dabei erfährst du alles Wichtige und
                Wissenswerte rund um deine neue Arbeitgeber.
              </p>
              <h3>Einarbeitung vor Ort</h3>
              <p>
                Anschließend erhältst du eine bereichsspezifische Einarbeitung
                in deinem Arbeitsbereich. In der Anfangsphase stehen dir
                ausgewählte Kolleg:innen als Partner:innen zur Seite, denen du
                über die Schulter schaust.{' '}
              </p>
              <h3>Gut ankommen</h3>
              <p>
                Neu in der Hansestadt? Deine Kolleg:innen haben garantiert heiße
                Tipps parat, was-wann-wo in Hamburg so läuft. Auch das gehört
                bei uns zur Einarbeitung.
              </p>
              <h3>Urlaubsreif?</h3>
              <p>
                Jeder Anfang ist aufregend und du wirst im UKE von vielen neuen
                Eindrücken geflutet. Das muss man erstmal verarbeiten. Damit
                dabei die Erholung nicht zu kurz kommt, kannst du bei uns schon
                während der sechsmonatigen Probezeit anteilig Urlaub nehmen.
              </p>
              <h3>Du willst mehr?</h3>
              <p>
                In unseren Bereichen finden regelmäßige, interne Fortbildungen
                statt; die Teilnahme an externen Angeboten ist ebenfalls
                möglich. Unsere{' '}
                <a href="https://www.uke.de/organisationsstruktur/zentrale-bereiche/uke-akademie-fuer-bildung-karriere/index.html">
                  Akademie für Bildung & Karriere (ABK)
                </a>{' '}
                bietet dir zudem vielfältige Möglichkeiten der Fort- und
                Weiterbildung.
              </p>
              <div className="grid-container grid-container--static align-items-center">
                <div className="image-container image-container--frame image-container--static">
                  <img
                    src="/img/mfa/BSS_MFA_Bild_5.jpg"
                    alt="BSS MFA Bild 5"
                    title="BSS MFA Bild 5"
                    loading="lazy"
                  />
                </div>
                <div className="image-container image-container--frame image-container--static">
                  <img
                    src="/img/mfa/BSS_MFA_Bild_6.jpg"
                    alt="BSS MFA Bild 6"
                    title="BSS MFA Bild 6"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-right">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Mach dich warm für den Start!</h2>
              <p>
                Stöbere durch die Fachbereiche und Stationen und kontaktiere den
                Bereich, der zu dir passt.
              </p>
            </div>
          </div>
        </section>

        <StationsShortlist area={AREA_MFA} stations={pageContext.stations} />

        <section id="stuermen" className="bg-img-green divider-right">
          <div className="row align-items-center text-center">
            <div className="col-md-3 offset-md-1 col-12">
              <img
                src="/img/illustration-pfleger-stuermen.png"
                alt="Illustration: Pfleger einzeln"
                height="311"
                width="145"
              />
            </div>
            <div className="col-md-4 offset-md-0 col-10 offset-1">
              <img
                src="/img/handlettering/handlettering-ich-bin-mfa-lasst-mich-durch.svg"
                alt="Handlettering: Ich bin MFA lasst mich durch!"
                width="400"
              />
            </div>
            <div className="col-md-3 col-12">
              <h4>
                Du weißt, was du willst:
                <br />
                Bewirb dich direkt!
              </h4>
              <Button
                color="green"
                href="mailto:bewerbung@uke.de?subject=MFA-Initiativbewerbung über die jukebox"
              >
                Stürmen
              </Button>
            </div>
          </div>
        </section>

        <section>
          <figure>
            <div className="row align-items-center">
              <div className="col-sm-5 offset-sm-1 col-12 order-sm-1 order-1 text-center">
                <img
                  src="img/mfa/nicole-reimer.jpg"
                  alt="Portrait: Nicole Kraft"
                  title="Nicole Kraft"
                  loading="lazy"
                />
              </div>
              <div className="col-sm-5 col-12 order-sm-2 order-3">
                <ul className="contact-channels">
                  <li className="phone">
                    <a href="tel:+4940741059794">+49 (0) 40 7 41 05 97 94</a>
                  </li>
                  {/*<li className="mobile"><a href="tel:+4915222">0152 22 82 </a></li>*/}
                  <li className="email">
                    <a href="mailto:n.kraft@uke.de">n.kraft@uke.de</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-5 offset-sm-1 col-12 order-sm-3 order-2">
                <figcaption>
                  Nicole Kraft
                  <br />
                  Recruiterin
                </figcaption>
              </div>
            </div>
          </figure>
        </section>

        <StaticReferenceModules referencesData={areaPageReferences} />
        <Footer />
      </main>
    </CMSWrapper>
  )
}

export const query = graphql`
  query MfaReferencesQuery($referenceType: [String]) {
    allReference(
      filter: { types: { in: $referenceType }, isDraft: { eq: false } }
    ) {
      nodes {
        _id
        content {
          sections {
            _template
            bgColor
            grids {
              _template
              columns {
                _template
                altText
                description
                descriptionAlignment
                showFrame
                filename
                listStyle
                text
                textAlign
                url
                images {
                  _template
                  altText
                  description
                  filename
                  showFrame
                }
              }
              itemAlignment
              marginType
            }
          }
        }
        mediaDir
        priority
        slug
        title
      }
    }
  }
`
